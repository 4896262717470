@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
}

body,
.ant-typography,
.ant-btn,
.ant-input,
.ant-menu,
.ant-form-item-label,
.ant-form-item-explain-error,
.ant-select-item-option-content,
.ant-select-item-option-selected,
.ant-select-item-option-active,
.ant-select-selection-item {
  font-family: "Outfit", sans-serif;
}

* {
  box-sizing: border-box;
  overscroll-behavior: none;
}

svg {
  -webkit-backface-visibility: hidden;
  will-change: transform;
}

.xpay-logo {
  width: 150px;
  height: auto;
}

.logo-subtext {
  font-size: 0.8rem;
  color: #191919;
  font-style: italic;
  text-align: center;
}

.stack {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-cont {
  padding: 2rem;
  min-height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  margin: auto;
}

.circular-loader {
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #ccc;
  border-right-color: #00a3d7;
  border-radius: 22px;
  -webkit-animation: rotate 1s infinite linear;
  animation: rotate 1s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.stripe-merchant-name {
  font-size: 1.2rem;
  color: #191919;
  margin-top: -1rem;
  margin-bottom: 2rem;
  b {
    font-weight: 600;
    color: #00a3d7;
  }
}

.stripe-payment-btn {
  background-color: #00a3d7;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 1rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 100%;
  margin-top: 2rem;
  &:hover {
    background-color: #0089b3;
  }
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.error-page-retry-btn {
  padding: 0.5rem 2rem;
  margin-top: 2rem;
  font-size: 1rem;
  cursor: pointer;
}

.payment-confirmation-cont {
  h3 {
    margin: 0 0 2rem;
    font-weight: 500;
    opacity: 0.87;
  }

  button {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    cursor: pointer;
  }
}

.error-message {
  text-align: center;
}

.button-styles {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.button-styles:hover {
  background-color: #45a049;
}

.button-styles:active {
  background-color: #3e8e41;
}

.success-hide {
  opacity: 0;
}

.success-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  transition: opacity 0.5s ease;
}

.success-circular-loader {
  height: 0;
  width: 0;
  padding: 6px;
  border: 4px solid #ccc;
  border-right-color: #00a3d7;
  border-radius: 22px;
  -webkit-animation: rotate 1s infinite linear;
  animation: rotate 1s infinite linear;
}

* {
  &::-webkit-scrollbar {
    width: 3px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

#currency .ant-select-selector {
  border-right: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-left: none;
  background: #ffffff;
  border-radius: 0 6px 6px 0;
}

#currency .ant-radio-button-wrapper:last-child {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

#method .ant-radio-button-wrapper {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
}

#method .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

#container .ant-spin-nested-loading .ant-spin-blur {
  clear: both;
  opacity: 0;
  user-select: none;
  pointer-events: none;
}

.ant-tag.ant-tag-gold {
  padding-bottom: 2px;
}

#currency .no-input .ant-select-selection-search-input {
  opacity: 0;
  width: 100%;
  caret-color: transparent;
}

#currency .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  opacity: 0;
}

